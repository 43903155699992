import { Text, useMultiStyleConfig } from '@chakra-ui/react';
import { COLOR_SCHEMES, COMPONENT_NAMES, TAriaLabel } from '@codeer/handypark-web-common';
import { ReactNode } from 'react';

export type TParkingCardOriginTemplateProps = {
  value: string;
  label: ReactNode;
  description?: ReactNode;
} & TAriaLabel;

export const ParkingCardOriginRadioButtonTemplate = ({
  label,
  description,
}: TParkingCardOriginTemplateProps) => {
  /* --- STYLES --- */
  const styles = useMultiStyleConfig(COMPONENT_NAMES.RADIO_BUTTON);

  return (
    <>
      <Text textStyle={'label'} sx={styles.title}>
        {label}
      </Text>
      {description && (
        <Text textStyle={'sub'} colorScheme={`${COLOR_SCHEMES.SHADE}.200`} mt={1}>
          {description}
        </Text>
      )}
    </>
  );
};

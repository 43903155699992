import { TPhoneNumberDataWithVerificationCode } from '@features/parking-card-country-code';

type TVerifyPhoneNumberVerificationCode = TPhoneNumberDataWithVerificationCode;
type TVerifyPhoneNumberVerificationCodeResponse = {
  verified: boolean;
};

export const verifyPhoneNumberVerificationCode = async (
  data: TVerifyPhoneNumberVerificationCode,
): Promise<TVerifyPhoneNumberVerificationCodeResponse> => {
  console.log('verifyPhoneNumberVerificationCode api', { data });
  // const url = PROXY_API_ENDPOINTS.VERIFY_PHONE_NUMBER_VERIFICATION_CODE();

  // await getProxyClient().post(url, verifyPhoneNumberVerificationCodeData);

  // DEV, since we don't know the endpoints yet
  const correctCodes = Array.from({ length: 10 }, (_, i) => i).map(num => String(num).repeat(4));
  const response: TVerifyPhoneNumberVerificationCodeResponse = correctCodes.includes(
    data.phoneNumberVerificationCode,
  )
    ? { verified: true }
    : { verified: false };

  return await new Promise(resolve => {
    return setTimeout(() => {
      resolve(response);
    }, 1000);
  });
};

import { useDataState } from '@codeer/handypark-web-common';
import { verifyPhoneNumberVerificationCodeAtom } from '@features/parking-card-country-code';
import { useAtom } from 'jotai';

export const useVerifyPhoneNumberVerificationCode = () => {
  const [{ mutateAsync, ...rest }] = useAtom(verifyPhoneNumberVerificationCodeAtom);

  const { isPending, error, isIdle } = rest;

  const verifyPhoneNumberVerificationCodeState = useDataState({
    isLoading: isPending,
    error,
    isIdle,
  });

  return {
    verifyPhoneNumberVerificationCode: mutateAsync,
    verifyPhoneNumberVerificationCodeState,
    verifyPhoneNumberVerificationCodeError: error,
    isVerifyingPhoneNumberVerificationCode: isPending,
  };
};

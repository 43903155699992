import { Box } from '@chakra-ui/react';
import {
  AppButtonEIdasComponent,
  AppButtonEIdComponent,
  AppButtonItsMe,
  SIGN_IN_INSTANCES,
  SUPPORTED_SIGN_IN_INSTANCES,
} from '@core';

type TSignInButtonsProps = {
  setIsLoading: (isTrue: boolean) => void;
};

export const SignInButtons = ({ setIsLoading }: TSignInButtonsProps) => {
  /* --- ACTIONS --- */
  const redirectToSignInInstance = (instance: string) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.pathname = `/api/auth/login/${instance}`;
    setIsLoading(true);
    window.location.href = url.toString();
  };

  return (
    <Box display={'flex'} flexWrap={'wrap'} gap={4} my={4} maxWidth={{ sm: '360px' }}>
      {SUPPORTED_SIGN_IN_INSTANCES.includes(SIGN_IN_INSTANCES.ITS_ME) && (
        <AppButtonItsMe onClick={() => redirectToSignInInstance(SIGN_IN_INSTANCES.ITS_ME)} />
      )}
      {SUPPORTED_SIGN_IN_INSTANCES.includes(SIGN_IN_INSTANCES.E_ID) && (
        <AppButtonEIdComponent onClick={() => redirectToSignInInstance(SIGN_IN_INSTANCES.E_ID)} />
      )}
      {SUPPORTED_SIGN_IN_INSTANCES.includes(SIGN_IN_INSTANCES.E_IDAS) && (
        <AppButtonEIdasComponent
          onClick={() => redirectToSignInInstance(SIGN_IN_INSTANCES.E_IDAS)}
        />
      )}
    </Box>
  );
};

import { ApiError } from '@codeer/handypark-common';
import { ErrorPage, ForbiddenPage, UnauthorizedPage } from '@codeer/handypark-web-common';
import { PageLayout } from '@core';

type TMainErrorProps = {
  error?: ApiError;
};

/*
 * This is the component which will render possible errors that occur in the main component.
 *
 * Possible errors:
 *
 * - getUserProfileError
 * - getUserError
 * */
export const MainError = ({ error }: TMainErrorProps) => {
  /* --- DATA --- */
  const status = error?.response.data.status;

  if (status) {
    switch (status) {
      case 401:
        return (
          <PageLayout>
            <UnauthorizedPage />
          </PageLayout>
        );
      case 403:
        return (
          <PageLayout>
            <ForbiddenPage />
          </PageLayout>
        );
      default:
        return (
          <PageLayout>
            <ErrorPage />
          </PageLayout>
        );
    }
  } else {
    return (
      <PageLayout>
        <ErrorPage />
      </PageLayout>
    );
  }
};

import { AppContainer, AppSpinner } from '@codeer/handypark-web-common';
import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_SELECT_COUNTRY_FORM_KEYS,
  TCountryOptionWithAriaLabel,
  TParkingCardCountryCodeForeignSelectCountryForm,
} from '@features/parking-card-country-code';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';

import { CountriesFilteredNoResults, CountriesFilteredResults, CountriesNotFiltered } from './';

import { divideCountriesByNeighbourhood, filterCountriesByQuery } from './countries.helper';

type TCountriesRadioButtonsProps = {
  data: TCountryOptionWithAriaLabel[];
  isLoading?: boolean;
  query?: string;
  name: PARKING_CARD_COUNTRY_CODE_FOREIGN_SELECT_COUNTRY_FORM_KEYS;
  control: Control<TParkingCardCountryCodeForeignSelectCountryForm>;
  onHandleSubmit: () => void;
};

export type TSortedCountries = {
  neighbouring: TCountryOptionWithAriaLabel[];
  other: TCountryOptionWithAriaLabel[];
};

export const Countries = ({
  data,
  isLoading = false,
  query = '',
  name,
  control,
  onHandleSubmit,
}: TCountriesRadioButtonsProps) => {
  /* --- DATA --- */
  const dividedCountries = useMemo(() => {
    return divideCountriesByNeighbourhood(data);
  }, [data]);

  const filteredCountries = useMemo(() => {
    return filterCountriesByQuery(data, query);
  }, [data, query]);

  if (isLoading) {
    return (
      <AppContainer flexGrow={1} mb={4}>
        <AppSpinner />
      </AppContainer>
    );
  }

  if (!query) {
    return (
      <CountriesNotFiltered
        dividedCountries={dividedCountries}
        name={name}
        control={control}
        onHandleSubmit={onHandleSubmit}
      />
    );
  }

  if (filteredCountries.length > 0) {
    return (
      <CountriesFilteredResults
        filteredCountries={filteredCountries}
        name={name}
        control={control}
        onHandleSubmit={onHandleSubmit}
      />
    );
  }

  return <CountriesFilteredNoResults />;
};

import { useDataState } from '@codeer/handypark-web-common';
import { getPhoneNumberVerificationCodeAtom } from '@features/parking-card-country-code';
import { useAtom } from 'jotai';

export const useGetPhoneNumberVerificationCode = () => {
  const [{ mutateAsync, ...rest }] = useAtom(getPhoneNumberVerificationCodeAtom);

  const { isPending, error, isIdle } = rest;

  const getPhoneNumberVerificationCodeState = useDataState({ isLoading: isPending, error, isIdle });

  return {
    getPhoneNumberVerificationCode: mutateAsync,
    getPhoneNumberVerificationCodeState,
    getPhoneNumberVerificationCodeError: error,
    isGettingPhoneNumberVerificationCode: isPending,
  };
};

import { T_NAMESPACES } from '@codeer/handypark-common';
import { t } from 'i18next';
import * as yup from 'yup';
import { PARKING_CARD_COUNTRY_CODE_FOREIGN_SELECT_COUNTRY_FORM_KEYS } from './';

const tKeyPrefix = 'parking-card-country-code-foreign.select-country';
export const parkingCardCountryCodeForeignSelectCountryValidationSchema = () =>
  yup.object().shape({
    [PARKING_CARD_COUNTRY_CODE_FOREIGN_SELECT_COUNTRY_FORM_KEYS.COUNTRY_CODE]: yup
      .string()
      .required(
        t(`${tKeyPrefix}.country-code.validation.required`, {
          ns: T_NAMESPACES.PAGES,
        }),
      ),
  });

import { TPhoneNumberData } from '@features/parking-card-country-code';

type TGetPhoneNumberVerificationCode = TPhoneNumberData;
export const getPhoneNumberVerificationCode = async (data: TGetPhoneNumberVerificationCode) => {
  console.log('getPhoneNumberVerificationCode api', { data });
  // const url = PROXY_API_ENDPOINTS.GET_PHONE_NUMBER_VERIFICATION_CODE();

  // await getProxyClient().post(url, getPhoneNumberVerificationCodeData);

  // DEV, since we don't know the endpoints yet
  return await new Promise<void>(resolve => {
    return setTimeout(() => {
      resolve();
    }, 1000);
  });
};

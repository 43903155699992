import { AppFormSuccessMessages, TAppFormSuccessMessage } from '@/lib/web-common';
import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  APP_BUTTON_VARIANTS,
  AppButton,
  AppContainer,
  AppFormErrors,
  AppTrans,
  ControlledCodeInput,
  getErrorMessages,
  Paragraph,
  stripTags,
  windowScrollTop,
} from '@codeer/handypark-web-common';
import { toReadableAriaPhoneNumber } from '@core';
import {
  phoneNumberAtom,
  phoneNumberOriginAtom,
  TPhoneNumberData,
  TPhoneNumberDataWithVerificationCode,
  useGetPhoneNumberVerificationCode,
  useVerifyPhoneNumberVerificationCode,
} from '@features/parking-card-country-code';

import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue } from 'jotai';
import { ReactNode, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS,
  parkingCardCountryCodeForeignConfirmPhoneNumberValidationSchema,
  TParkingCardCountryCodeForeignConfirmPhoneNumberForm,
} from './';

const defaultValues = {
  [PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS.CODE]: '',
};

const keyPrefix = 'parking-card-country-code-foreign.confirm-phone-number';
export const ParkingCardCountryCodeForeignConfirmPhoneNumberForm = () => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: `${keyPrefix}`,
  });

  /* --- ATOMS --- */
  const phoneNumber = useAtomValue(phoneNumberAtom);
  const phoneNumberOrigin = useAtomValue(phoneNumberOriginAtom);

  /* --- HOOKS --- */
  const { getPhoneNumberVerificationCode, isGettingPhoneNumberVerificationCode } =
    useGetPhoneNumberVerificationCode();
  const { verifyPhoneNumberVerificationCode, isVerifyingPhoneNumberVerificationCode } =
    useVerifyPhoneNumberVerificationCode();
  const isCallLoading =
    isGettingPhoneNumberVerificationCode || isVerifyingPhoneNumberVerificationCode;

  /* --- STATE --- */
  const [formErrors, setFormErrors] = useState<ReactNode[]>([]);
  const [formSuccessMessages, setFormSuccessMessages] = useState<TAppFormSuccessMessage[]>([]);

  /* --- FORM --- */
  const { handleSubmit, control, clearErrors, reset } =
    useForm<TParkingCardCountryCodeForeignConfirmPhoneNumberForm>({
      resolver: yupResolver(parkingCardCountryCodeForeignConfirmPhoneNumberValidationSchema()),
      mode: 'onSubmit',
      values: defaultValues,
      defaultValues,
    });

  const onSubmit: SubmitHandler<
    TParkingCardCountryCodeForeignConfirmPhoneNumberForm
  > = async data => {
    resetFormErrors();
    resetFormSuccessMessages();

    const phoneNumberDataWithVerificationCode: TPhoneNumberDataWithVerificationCode = {
      phoneNumber,
      phoneNumberOrigin,
      phoneNumberVerificationCode:
        data[PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS.CODE],
    };

    // Verify the verification code to phone number that is filled
    try {
      const verifiedData = await verifyPhoneNumberVerificationCode(
        phoneNumberDataWithVerificationCode,
      );

      if (verifiedData.verified) {
        onVerifiedSuccess();
      } else {
        onVerifiedError();
      }
    } catch (error) {
      console.log(error);
      // TODO: Add alert that something went wrong
    }
  };

  const onError: SubmitErrorHandler<
    TParkingCardCountryCodeForeignConfirmPhoneNumberForm
  > = data => {
    const newFormErrors = getErrorMessages(data);
    setFormErrors(newFormErrors);
    windowScrollTop();
  };

  /* --- ACTIONS --- */
  const resetFormErrors = () => {
    setFormErrors([]);
  };

  const resetFormSuccessMessages = () => {
    setFormSuccessMessages([]);
  };

  const onRequestNewCodeHandler = async () => {
    resetFormErrors();
    resetFormSuccessMessages();

    const phoneNumberData: TPhoneNumberData = {
      phoneNumber,
      phoneNumberOrigin,
    };

    // Send the verification code to phone number that is filled
    try {
      await getPhoneNumberVerificationCode(phoneNumberData);

      const newFormSuccessMessage: TAppFormSuccessMessage = {
        id: 'has-requested-new-code',
        content: (
          <AppTrans
            i18nKey={`${keyPrefix}.alert.requested-new-code`}
            ns={T_NAMESPACES.PAGES}
            values={{
              phoneNumber,
            }}
          />
        ),
        ariaLabel: stripTags(
          t('alert.requested-new-code', {
            phoneNumber: toReadableAriaPhoneNumber(phoneNumber),
          }),
        ),
      };
      setFormSuccessMessages(prev => [...prev, newFormSuccessMessage]);

      clearErrors(PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS.CODE);
      reset(defaultValues);
    } catch (error) {
      console.log(error);
      // TODO: Add alert with data
    }
  };

  // Wrap all functions that need to be done when submitting the form
  const onHandleSubmitHandler = () => {
    handleSubmit(onSubmit, onError)();
  };

  const onVerifiedSuccess = () => {
    const newFormSuccessMessage: TAppFormSuccessMessage = {
      // TODO: Translate
      content: 'Verified!',
    };
    setFormSuccessMessages(prev => [...prev, newFormSuccessMessage]);

    // TODO: Redirect user?
  };

  const onVerifiedError = () => {
    // TODO: Translate
    const newFormError = 'Not verified!';
    setFormErrors(prev => [...prev, newFormError]);
  };

  return (
    <form onSubmit={onHandleSubmitHandler}>
      <AppContainer mb={8} display={'flex'} flexDirection={'column'} gap={8}>
        <Box display={'flex'} flexDirection={'column'} gap={4}>
          <Paragraph
            aria-hidden={true}
            role={'presentation'}
            aria-label={stripTags(
              t('description.code-received', {
                phoneNumber: toReadableAriaPhoneNumber(phoneNumber),
              }),
            )}
          >
            <AppTrans
              i18nKey={`${keyPrefix}.description.code-received`}
              ns={T_NAMESPACES.PAGES}
              values={{
                phoneNumber,
              }}
            />
          </Paragraph>
          <Paragraph>
            <AppTrans i18nKey={`${keyPrefix}.description.fill-code`} ns={T_NAMESPACES.PAGES} />
          </Paragraph>
        </Box>

        <AppFormErrors formErrors={formErrors} />
        <AppFormSuccessMessages successMessages={formSuccessMessages} />

        <ControlledCodeInput
          name={PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS.CODE}
          control={control}
          onSubmit={onHandleSubmitHandler}
        />
      </AppContainer>
      <AppContainer
        mb={8}
        display={'flex'}
        flexDirection={{ base: 'column', 'sm-md': 'row' }}
        gap={4}
      >
        <AppButton onClick={onHandleSubmitHandler} isLoading={isCallLoading}>
          {t('form.submit')}
        </AppButton>
        <AppButton
          variant={APP_BUTTON_VARIANTS.OUTLINE}
          onClick={onRequestNewCodeHandler}
          isLoading={isCallLoading}
        >
          {t('form.request-new-code')}
        </AppButton>
      </AppContainer>
    </form>
  );
};

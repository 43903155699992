import { Text } from '@chakra-ui/react';
import { T_NAMESPACES, TCountryOption } from '@codeer/handypark-common';
import { AppFlagIcon } from '@codeer/handypark-web-common';
import * as flags from 'country-flag-icons/react/3x2';
import { useTranslation } from 'react-i18next';
import { components, OptionProps } from 'react-select';

export const CountrySelectInputElementOption = (props: OptionProps<TCountryOption>) => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.COUNTRIES);

  /* --- DATA --- */
  const { data } = props;

  return (
    <components.Option {...props}>
      <AppFlagIcon country={data.value as keyof typeof flags} alignSelf={'center'} me={2} />
      <Text as={'span'} display={'inline-block'}>
        {t(data.value)}
      </Text>
    </components.Option>
  );
};

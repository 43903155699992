import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppFormErrors,
  isInfoModalOpenAtom,
  PageHeaderActions,
} from '@codeer/handypark-web-common';
import {
  ParkingCardCountryCodeBelgianForm,
  ParkingCardCountryCodeBelgianInfoModal,
} from '@features/parking-card-country-code';
import { useSetAtom } from 'jotai';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ParkingCardCountryCodeBelgian = () => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-card-country-code-belgian',
  });

  /* --- ATOMS --- */
  const setInfoModalAtom = useSetAtom(isInfoModalOpenAtom);

  /* --- STATE --- */
  const [formErrors, setFormErrors] = useState<ReactNode[]>([]);

  return (
    <>
      <PageHeaderActions
        title={t('title')}
        showPageHeaderActionsLeft={false}
        onInfoHandler={() => setInfoModalAtom(true)}
      />
      <ParkingCardCountryCodeBelgianInfoModal />

      <AppFormErrors formErrors={formErrors} mb={4} />

      <ParkingCardCountryCodeBelgianForm setFormErrors={setFormErrors} />
    </>
  );
};

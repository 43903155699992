import { TCountry, TCountryOption } from '@codeer/handypark-common';
import { sortAlphabetical } from '@codeer/handypark-web-common';

export const getCountryOptions = (countries: TCountry[]) => {
  if (!countries) {
    return [];
  }

  /* Get the following groups of options
   * Sort them alphabetically:
   *
   * - Belgium
   * - Neighbouring countries
   * - Other
   * */

  const belgianOptions = countries.filter(country => country.iso2Code === 'BE');
  const neighbouringCountries = sortAlphabetical(
    countries.filter(country => country.isNeighbouringCountry),
    'name',
  );
  const otherCountries = sortAlphabetical(
    countries.filter(country => !country.isNeighbouringCountry),
    'name',
  );

  const sortedCountries = [...belgianOptions, ...neighbouringCountries, ...otherCountries];

  return sortedCountries.map(
    ({ name, iso2Code }) =>
      ({
        label: name,
        value: iso2Code,
      }) as TCountryOption,
  );
};

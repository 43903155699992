import { EId } from '@/assets/icons';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppButton,
  TAppButtonProps,
  themeOutlineOffsets,
  themeOutlines,
} from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

type TAppButtonEIdComponentProps = TAppButtonProps;

export const AppButtonEIdComponent = ({
  qa = 'app-button-eid',
  ...rest
}: TAppButtonEIdComponentProps) => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, { keyPrefix: 'button.sign-in' });

  return (
    <AppButton
      {...rest}
      color={'white'}
      backgroundColor={'#87cea3'}
      sx={{
        boxShadow: 'none',
        backgroundColor: '#87cea3',
        _hover: {
          backgroundColor: '#87cea3',
        },
        _focus: {
          backgroundColor: '#87cea3',
        },
        _focusVisible: {
          outline: themeOutlines.md,
          outlineOffset: themeOutlineOffsets.md,
          outlineColor: '#87cea3',
          backgroundColor: '#87cea3',
        },
      }}
    >
      <EId aria-hidden={true} me={2} />
      {t('e-id')}
    </AppButton>
  );
};

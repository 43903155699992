import { PROXY_QUERY_KEYS } from '@core';
import {
  phoneNumberAtom,
  phoneNumberOriginAtom,
  phoneNumberVerificationCodeAtom,
  TPhoneNumberDataWithVerificationCode,
  verifyPhoneNumberVerificationCode,
} from '@features/parking-card-country-code';
import { atomWithMutation } from 'jotai-tanstack-query';

export const verifyPhoneNumberVerificationCodeAtom = atomWithMutation(get => {
  const phoneNumber = get(phoneNumberAtom);
  const phoneNumberOrigin = get(phoneNumberOriginAtom);
  const phoneNumberVerificationCode = get(phoneNumberVerificationCodeAtom);

  const phoneNumberDataWithVerificationCode: TPhoneNumberDataWithVerificationCode = {
    phoneNumber,
    phoneNumberOrigin,
    phoneNumberVerificationCode,
  };

  return {
    mutationKey: [
      PROXY_QUERY_KEYS.VERIFY_PHONE_NUMBER_VERIFICATION_CODE,
      Object.values(phoneNumberDataWithVerificationCode),
    ],
    mutationFn: (phoneNumberDataWithVerificationCode: TPhoneNumberDataWithVerificationCode) =>
      verifyPhoneNumberVerificationCode(phoneNumberDataWithVerificationCode),
  };
});

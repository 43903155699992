import { IRouteConfig } from '@codeer/handypark-web-common';
import { PARKING_CARD_COUNTRY_CODE_ROUTES } from '@features/parking-card-country-code/constants';
import { lazy } from 'react';

const ParkingCardCountryCodeForeignPage = lazy(() =>
  import('@features/parking-card-country-code/pages').then(module => ({
    default: module.ParkingCardCountryCodeForeignPage,
  })),
);

export const parkingCardCountryCodeRoutes: () => IRouteConfig[] = () => [
  {
    path: PARKING_CARD_COUNTRY_CODE_ROUTES().FOREIGN,
    component: ParkingCardCountryCodeForeignPage,
  },
  {
    path: `/*`,
    redirect: '/',
  },
];

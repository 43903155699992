import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppHeading,
  AppModalInfo,
  HEADING_STYLING_KEYS,
  Paragraph,
  transComponents,
} from '@codeer/handypark-web-common';
import { TAppOrigin } from '@features/app-origin';
import { Trans, useTranslation } from 'react-i18next';

type TGeneralLoginInfoModalProps = Qa & {
  appOrigin: TAppOrigin;
};

const keyPrefix = 'general-login.info-modal';
export const GeneralLoginInfoModal = ({
  qa = 'info-modal',
  appOrigin,
}: TGeneralLoginInfoModalProps) => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix,
  });
  const { t: tAppOrigin } = useTranslation(T_NAMESPACES.COMMON, { keyPrefix: 'app-origin' });

  return (
    <AppModalInfo qa={qa} title={t('title', { appOrigin: tAppOrigin(appOrigin) })}>
      <AppHeading as={HEADING_STYLING_KEYS.H2} textStyle={HEADING_STYLING_KEYS.H3} mb={4}>
        <Trans
          i18nKey={`${keyPrefix}.section1.title`}
          ns={T_NAMESPACES.PAGES}
          components={transComponents}
        />
      </AppHeading>
      <Paragraph mb={4}>
        <Trans
          i18nKey={`${keyPrefix}.section1.paragraph`}
          ns={T_NAMESPACES.PAGES}
          components={transComponents}
        />
      </Paragraph>
    </AppModalInfo>
  );
};

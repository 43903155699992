import { IRouteConfig } from '@codeer/handypark-web-common';
import { APP_ORIGIN_SWITCH_ROUTES } from '@features/app-origin/constants';
import { lazy } from 'react';

const AppOriginSwitchPage = lazy(() =>
  import('@features/app-origin/pages').then(module => ({
    default: module.AppOriginSwitchPage,
  })),
);

export const appOriginSwitchRoutes: () => IRouteConfig[] = () => [
  {
    path: APP_ORIGIN_SWITCH_ROUTES().BASE,
    component: AppOriginSwitchPage,
  },
];

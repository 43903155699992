import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { AppHeading, AppModalInfo, Paragraph, transComponents } from '@codeer/handypark-web-common';
import { Trans, useTranslation } from 'react-i18next';

type TParkingCardCountryCodeBelgianInfoModalProps = Qa;

const keyPrefix = 'parking-card-country-code-belgian.info';
export const ParkingCardCountryCodeBelgianInfoModal = ({
  qa = 'info-modal',
}: TParkingCardCountryCodeBelgianInfoModalProps) => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix,
  });

  return (
    <AppModalInfo qa={qa} title={t('title')}>
      <Paragraph mb={4}>
        <Trans
          i18nKey={`${keyPrefix}.section1.content.paragraph1`}
          ns={T_NAMESPACES.PAGES}
          components={transComponents}
        />
      </Paragraph>
      <Paragraph mb={4}>
        <Trans
          i18nKey={`${keyPrefix}.section1.content.paragraph2`}
          ns={T_NAMESPACES.PAGES}
          components={transComponents}
        />
      </Paragraph>
      <Paragraph mb={4}>
        <Trans
          i18nKey={`${keyPrefix}.section1.content.paragraph3`}
          ns={T_NAMESPACES.PAGES}
          components={transComponents}
        />
      </Paragraph>

      <AppHeading as={'h2'} mb={4}>
        {t('section2.title')}
      </AppHeading>

      <Paragraph mb={4}>
        <Trans
          i18nKey={`${keyPrefix}.section2.content.paragraph1`}
          ns={T_NAMESPACES.PAGES}
          components={transComponents}
        />
      </Paragraph>
      <Paragraph mb={4}>
        <Trans
          i18nKey={`${keyPrefix}.section2.content.paragraph2`}
          ns={T_NAMESPACES.PAGES}
          components={transComponents}
        />
      </Paragraph>
      <Paragraph mb={4}>
        <Trans
          i18nKey={`${keyPrefix}.section2.content.paragraph3`}
          ns={T_NAMESPACES.PAGES}
          components={transComponents}
        />
      </Paragraph>
    </AppModalInfo>
  );
};

import { T_NAMESPACES } from '@codeer/handypark-common';
import { t } from 'i18next';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';
import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS,
  TParkingCardCountryCodeForeignFillPhoneNumberForm,
} from './';

const tKeyPrefix = 'parking-card-country-code-foreign.fill-phone-number.form';
const tPhoneNumber = 'form.input.phone-number';

export const parkingCardCountryCodeForeignFillPhoneNumberValidationSchema = () =>
  yup.object().shape({
    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.PHONE_NUMBER_ORIGIN]: yup
      .string()
      .required(t(`${tPhoneNumber}.required`, { ns: T_NAMESPACES.COMPONENTS })),
    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.PHONE_NUMBER]: yup
      .string()
      .test('check if phone number is valid', '', function (value) {
        if (value === '') {
          return this.createError({
            message: t(`${tPhoneNumber}.required`, { ns: T_NAMESPACES.COMPONENTS }),
          });
        }

        const transformedPhoneNumber = value as string;
        const parent: TParkingCardCountryCodeForeignFillPhoneNumberForm = this.parent;

        // Check if this exists will be done in the 'phoneNumberOrigin' schema
        const { phoneNumberOrigin } = parent;

        const transformedPhoneNumberOrigin = phoneNumberOrigin.toUpperCase() as CountryCode;

        const _isValidPhoneNumber = isValidPhoneNumber(
          transformedPhoneNumber,
          transformedPhoneNumberOrigin,
        );

        if (!_isValidPhoneNumber) {
          return this.createError({
            message: t(`${tPhoneNumber}.invalid`, { ns: T_NAMESPACES.COMPONENTS }),
          });
        }

        const parsedPhoneNumber = parsePhoneNumber(
          transformedPhoneNumber,
          transformedPhoneNumberOrigin,
        );

        if (!parsedPhoneNumber) {
          return this.createError({
            message: t(`${tPhoneNumber}.invalid`, { ns: T_NAMESPACES.COMPONENTS }),
          });
        }

        // Check if country code matches the phoneNumberOrigin
        if (parsedPhoneNumber.country !== phoneNumberOrigin) {
          return this.createError({
            message: t(`${tPhoneNumber}.invalid`, { ns: T_NAMESPACES.COMPONENTS }),
          });
        }
        return true;
      })
      .required(t(`${tPhoneNumber}.required`, { ns: T_NAMESPACES.COMPONENTS })),

    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.ACCEPT_PRIVACY_POLICY]: yup
      .bool()
      .test(
        'mustBeTrue',
        t(`${tKeyPrefix}.accept-privacy-policy.validation.required`, { ns: T_NAMESPACES.PAGES }),
        val => {
          return val;
        },
      )
      .required(
        t(`${tKeyPrefix}.accept-privacy-policy.validation.required`, { ns: T_NAMESPACES.PAGES }),
      ),

    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.ACCEPT_PROFESSIONAL_MESSAGES]:
      yup.bool(),
  });

import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppButtonNext, AppContainer, TAppBoxProps } from '@codeer/handypark-web-common';
import { CountryRadioButtons } from '@core';
import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_SELECT_COUNTRY_FORM_KEYS,
  TParkingCardCountryCodeForeignSelectCountryForm,
  TSortedCountries,
} from '@features/parking-card-country-code';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TCountriesNotFilteredProps = TAppBoxProps & {
  dividedCountries: TSortedCountries;

  name: PARKING_CARD_COUNTRY_CODE_FOREIGN_SELECT_COUNTRY_FORM_KEYS;
  control: Control<TParkingCardCountryCodeForeignSelectCountryForm>;
  onHandleSubmit: () => void;
};

export const CountriesNotFiltered = ({
  qa = 'countries-not-filtered',
  dividedCountries,
  name,
  control,
  onHandleSubmit,
  ...rest
}: TCountriesNotFilteredProps) => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-card-country-code-foreign.select-country',
  });

  return (
    <Box data-qa={qa} {...rest}>
      <form onSubmit={onHandleSubmit}>
        <AppContainer flexGrow={1} mb={4}>
          {/* Neighbouring countries */}
          <CountryRadioButtons
            label={t('neighboring-countries.title')}
            name={name}
            control={control}
            options={dividedCountries.neighbouring}
            wrapItems
            showErrorMessage={false}
            mb={8}
          />

          {/* Other European countries */}
          <CountryRadioButtons
            label={t('other-countries.title')}
            name={name}
            control={control}
            options={dividedCountries.other}
            wrapItems
            showErrorMessage={false}
            mb={4}
          />
        </AppContainer>

        <AppContainer position={'sticky'} bottom={4} mb={4}>
          <AppButtonNext type={'submit'} onClick={onHandleSubmit} />
        </AppContainer>
      </form>
    </Box>
  );
};

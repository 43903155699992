import {
  ALERT_STATUS,
  APP_CONTAINER_VARIANTS,
  AppAlert,
  AppContainer,
  TAppAlert,
  TAppContainerProps,
} from '@codeer/handypark-web-common';

export type TAppFormSuccessMessage = Pick<TAppAlert, 'content' | 'ariaLabel'> &
  Partial<Pick<TAppAlert, 'id'>>;
type TAppFormSuccessMessagesProps = TAppContainerProps & {
  successMessages: TAppFormSuccessMessage[];
};

export const AppFormSuccessMessages = ({
  successMessages,
  ...rest
}: TAppFormSuccessMessagesProps) => {
  if (successMessages.length === 0) {
    return <></>;
  }

  return (
    <AppContainer variant={APP_CONTAINER_VARIANTS.FULL_WIDTH} {...rest}>
      {successMessages.map((successMessage, index) => (
        <AppAlert
          key={successMessage.id ?? index.toString()}
          alert={{
            id: successMessage.id ?? index.toString(),
            status: ALERT_STATUS.POSITIVE,
            content: successMessage.content,
            ariaLabel: successMessage.ariaLabel,
          }}
          canClose={false}
        />
      ))}
    </AppContainer>
  );
};

import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppContainer,
  AppSpinner,
  isInfoModalOpenAtom,
  PageHeaderActions,
} from '@codeer/handypark-web-common';
import { AppButtonItsMe, SIGN_IN_INSTANCES } from '@core';
import { GeneralLoginInfoModal, TAppOrigin } from '@features/app-origin';
import { useSetAtom } from 'jotai/index';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type TGeneralLoginProps = {
  appOrigin: TAppOrigin;
};

export const GeneralLogin = ({ appOrigin }: TGeneralLoginProps) => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'general-login',
  });
  const { t: tAppOrigin } = useTranslation(T_NAMESPACES.COMMON, { keyPrefix: 'app-origin' });
  const { t: tSignIn } = useTranslation(T_NAMESPACES.COMMON, {
    keyPrefix: 'sign-in',
  });

  /* --- HOOKS --- */
  const setInfoModalAtom = useSetAtom(isInfoModalOpenAtom);

  /* --- STATE --- */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /* --- ACTIONS --- */
  const redirectToSignInInstance = (instance: string) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.pathname = `/api/auth/login/${instance}`;
    setIsLoading(true);
    window.location.href = url.toString();
  };

  return (
    <Box h={'full'} display={'flex'} flexDirection={'column'}>
      <PageHeaderActions
        title={t('title', { appOrigin: tAppOrigin(appOrigin) })}
        showPageHeaderActionsLeft={false}
        onInfoHandler={() => setInfoModalAtom(true)}
        sx={{ mb: '0 !important' }}
      />
      <GeneralLoginInfoModal appOrigin={appOrigin} />

      <AppContainer
        flexGrow={1}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        py={10}
      >
        {isLoading ? (
          <AppSpinner showText={true} label={tSignIn('redirect-label')} />
        ) : (
          <AppButtonItsMe
            alignSelf={'center'}
            onClick={() => redirectToSignInInstance(SIGN_IN_INSTANCES.ITS_ME)}
          />
        )}
      </AppContainer>
    </Box>
  );
};

import { TCountryOption } from '@codeer/handypark-common';
import { AppFlagIcon } from '@codeer/handypark-web-common';
import * as flags from 'country-flag-icons/react/3x2';
import { components, ControlProps } from 'react-select';

export const CountrySelectInputElementControl = ({
  children,
  ...props
}: ControlProps<TCountryOption>) => {
  /* --- DATA --- */
  const data = props.getValue()[0];

  return (
    <components.Control {...props}>
      {data && (
        <AppFlagIcon
          country={data.value as keyof typeof flags}
          alignSelf={'center'}
          ms={3}
          me={2}
        />
      )}
      {children}
    </components.Control>
  );
};

// import '@codeer/handypark-common/fonts/Figtree/figtree.font.scss';

import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { queryClient, TGeneralContextProviderProps } from '@codeer/handypark-web-common';
import { HeadTags, theme } from '@core';

import { QueryClientProvider } from '@tanstack/react-query';
import { Provider as JotaiProvider } from 'jotai';
import { FC } from 'react';

export const MainProvider: FC<TGeneralContextProviderProps> = ({ children }) => {
  if (HP.SHOW_DEV_SETTINGS) {
    console.log({ theme: theme() });
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme()}>
        <CSSReset />
        <JotaiProvider>
          <HeadTags />
          {children}
        </JotaiProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

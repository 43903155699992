import { TNavLink } from '@codeer/handypark-web-common';

export const NAV_LINKS = (): TNavLink[] => {
  const devNavLinks = [
    {
      text: 'Playground',
      href: '/playground',
    },
  ];

  return [...(HP.SHOW_DEV_SETTINGS ? devNavLinks : [])];
};

import { EmojiEyesImage } from '@/assets/images';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppContainer,
  HEADING_STYLING_KEYS,
  PageHeader,
  Paragraph,
  STATUS_SCREEN_STATUS,
  StatusScreenCircleImage,
} from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

export const CountriesFilteredNoResults = () => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-card-country-code-foreign.select-country.search-results.empty',
  });

  return (
    <AppContainer
      textAlign={'center'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <StatusScreenCircleImage status={STATUS_SCREEN_STATUS.INFO} mb={10}>
        <EmojiEyesImage w={16} />
      </StatusScreenCircleImage>

      <PageHeader as={HEADING_STYLING_KEYS.H2} variant={'mobile'} mb={4}>
        {t('title')}
      </PageHeader>

      <Paragraph my={4}>{t('description')}</Paragraph>
    </AppContainer>
  );
};

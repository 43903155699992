import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppButtonNext, AppContainer } from '@codeer/handypark-web-common';
import { CountryRadioButtons } from '@core';
import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_SELECT_COUNTRY_FORM_KEYS,
  TCountryOptionWithAriaLabel,
  TParkingCardCountryCodeForeignSelectCountryForm,
} from '@features/parking-card-country-code';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TCountriesFilteredResultsProps = {
  filteredCountries: TCountryOptionWithAriaLabel[];

  name: PARKING_CARD_COUNTRY_CODE_FOREIGN_SELECT_COUNTRY_FORM_KEYS;
  control: Control<TParkingCardCountryCodeForeignSelectCountryForm>;
  onHandleSubmit: () => void;
};

export const CountriesFilteredResults = ({
  filteredCountries,
  name,
  control,
  onHandleSubmit,
}: TCountriesFilteredResultsProps) => {
  /* --- TRANSLATIONS --- */
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-card-country-code-foreign.select-country',
  });

  /* --- DATA --- */
  const resultCountKey =
    filteredCountries.length > 1 ? 'search-results.count_plural' : 'search-results.count';

  return (
    <>
      <form onSubmit={onHandleSubmit}>
        <AppContainer mb={4}>
          <CountryRadioButtons
            label={t(resultCountKey, { count: filteredCountries.length })}
            name={name}
            control={control}
            options={filteredCountries}
            mb={4}
            wrapItems
            showErrorMessage={false}
          />
        </AppContainer>

        <AppContainer position={'sticky'} bottom={4}>
          <AppButtonNext type={'submit'} onClick={onHandleSubmit} />
        </AppContainer>
      </form>
    </>
  );
};

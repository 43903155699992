import { PROXY_QUERY_KEYS } from '@core';
import {
  getPhoneNumberVerificationCode,
  phoneNumberAtom,
  phoneNumberOriginAtom,
  TPhoneNumberData,
} from '@features/parking-card-country-code';
import { atomWithMutation } from 'jotai-tanstack-query';

export const getPhoneNumberVerificationCodeAtom = atomWithMutation(get => {
  const phoneNumber = get(phoneNumberAtom);
  const phoneNumberOrigin = get(phoneNumberOriginAtom);

  const phoneNumberData: TPhoneNumberData = {
    phoneNumber,
    phoneNumberOrigin,
  };

  return {
    mutationKey: [
      PROXY_QUERY_KEYS.GET_PHONE_NUMBER_VERIFICATION_CODE,
      Object.values(phoneNumberData),
    ],
    mutationFn: (phoneNumberData: TPhoneNumberData) =>
      getPhoneNumberVerificationCode(phoneNumberData),
  };
});
